<template>
  <div class="w-full max-w-6xl mx-auto px-6 md:px-12 pt-24">
    <h1 class="text-center text-3xl font-written">Jouw Favorieten</h1>

    <div v-if="recipes.length && !favorites.length" class="text-center mt-8">
      Je hebt nog geen favorieten opgeslagen.
    </div>

    <div class="flex flex-wrap mt-20 lg:mt-4">
      <RecipeTile
        v-for="(recipe, index) in favorites" :key="recipe.id"
        class="w-1/2 sm:w-1/3 lg:w-1/4"
        :class="{
          '-mt-20 mb-20 sm:my-0': index % 2 === 0,
          'sm:-mt-20 sm:mb-20 lg:my-0': index % 3 !== 1
        }"
        :recipe="recipe"
      />
    </div>
  </div>
</template>

<script>
import store from '../store'
import http from '../http'
import RecipeTile from '@/components/RecipeTile2'

export default {
  components: {
    RecipeTile
  },
  data () {
    return {
      recipes: []
    }
  },
  mounted () {
    this.getRecipes()
  },
  computed: {
    favorites () {
      return this.recipes.filter(recipe => store.favorites.includes(recipe.id))
    }
  },
  methods: {
    async getRecipes () {
      const res = await http.get('/api/public/recipes')

      this.recipes = res.data
    }
  }
}
</script>
